<template>
    <div>
        <v-data-table 
        :loading="loadingTable" 
        :headers="headers" 
        :items="accommodations"  
        class="elevation-1"
        :page-count="numberOfPagesPaginate"
        :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate"
      :options.sync="options"
      :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 20],
        showFirstLastPage: true,
      }"
        >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Hospedagens</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <!-- <v-menu :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                    <v-icon>
                                        {{ icons.mdiTools }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Ferramentas</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <v-list-item @click="sendExportExcel()">
                            <v-list-item-title>
                                <v-icon>{{ icons.mdiFileExcelOutline  }}</v-icon> Exportar Excel
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu> -->
                <v-btn @click="dialogFilter=true" class="ml-2" rounded outlined large color="primary"><v-icon>{{icons.mdiFilter }}</v-icon>Filtros</v-btn>
                <v-text-field  :append-icon="icons.mdiMagnify" @click:append="confirmSearch()" @keyup.enter="confirmSearch()"  class="mt-6 ml-2" dense rounded outlined placeholder="Pesquisar por ID, HOSPEDES" label="Pesquisar"  v-model="filter.search"></v-text-field>
                <v-spacer></v-spacer>
                <label style="color:black" for="">{{$route.params.company}}</label>
            </v-toolbar>
        </template>
        
        <template v-slot:item.guests="{ item }">
            {{allNameGuest(item)}}
        </template>

        <template v-slot:item.total_accommodation="{ item }">
           {{ formatMoney(item.total_accommodation) }}
        </template>

        <template v-slot:item.total_consumption="{ item }">
           {{ formatMoney(item.total_consumption) }}
        </template>

        <template v-slot:item.checkin="{ item }">
           {{ formatDateTime(item.date_checkin) }}
        </template>

        <template v-slot:item.checkout="{ item }">
           {{ formatDateTime(item.checkout.date_sale) }}
        </template>

        
        <template v-slot:item.actions="{ item }">
          <div style=" white-space: nowrap;">
             <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  @click="showDetails(item.id)" :loading="item.loading_print" :disabled="item.loading_print" color="primary"  class="mr-2"  v-bind="attrs" v-on="on">
                  <v-icon  >
                    {{ icons.mdiMagnify  }}
                </v-icon>
                </v-btn>

            </template>

            <span>Detalhes</span>
            </v-tooltip>
          </div>
        </template>  


        <template v-slot:foot="{ item }">
            <tr style="height:44px; background:#fafafa" >
                 <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL NIGHT ROOMS: <b>{{countTotalDiary}} </b></td>
                 <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
                 <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
                 <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
                 <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
                 <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
            </tr>
            
            
        </template>


        <!-- <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template> -->
        <template v-slot:loading>
            Carregando...
        </template>
    </v-data-table>



<!-- DIALOG FILTROS  -->
  <v-dialog v-model="dialogFilter" width="700">
        <v-card>
            <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field hide-details v-model="filter.date_checkin_start" outlined type="date" label="Data Checkin Inicial"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field hide-details v-model="filter.date_checkin_end" outlined type="date" label="Data Checkin Final"></v-text-field>
                    </v-col>
                    
                </v-row>
                
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-row>
                    <v-col class="text-left">
                        <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
                        <v-btn @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>


    </div>
</template>
<script>
import {
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiOpenInNew,
  mdiTools,
  mdiFileExcelOutline,
  mdiFilter,
  mdiMagnify,
} from '@mdi/js'
import { mapActions } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
export default {
  components: {
    BarTitleDialog,
  },
  data: () => ({
    countTotalDiary: 0,
    dialogFilter: false,
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,

    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'CHECKIN',
        align: 'center',
        value: 'checkin',
      },
      {
        text: 'CHECKOUT',
        align: 'center',
        value: 'checkout',
      },
      {
        text: 'QUARTO',
        align: 'center',
        value: 'room.description',
      },
      {
        text: 'DIÁRIAS',
        align: 'center',
        value: 'count_diary',
      },
      {
        text: 'HOSPEDAGEM (R$)',
        align: 'end',
        value: 'total_accommodation',
      },
      {
        text: 'CONSUMO (R$)',
        align: 'end',
        value: 'total_consumption',
      },
      {
        text: 'HOSPEDES',
        align: 'start',
        value: 'guests',
      },
    ],
    accommodations: [],
    filter: { search: '' },
    options: {},
    icons: {
      mdiClose,
      mdiDeleteOutline,
      mdiPencilOutline,
      mdiPlus,
      mdiOpenInNew,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
      mdiMagnify,
    },
  }),
  created() {
    this.initialize()
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
    },
  },

  computed: {},

  methods: {
    ...mapActions('production_by_company', ['getAllAccomodation']),
    initialize() {
      const { page, itemsPerPage } = this.options

      this.filter = Object.assign(
        {
          company_id: this.$route.params.id,
          itemsPerPage: itemsPerPage || 10,
          page,
          search: this.filter.search,
        },
        this.filter,
      )

      this.loadingTable = true
      this.getAllAccomodation(this.filter)
        .then(response => {
          this.countTotalDiary = response.data.data.count_total_diary
          delete response.data.data.count_total_diary
          
          this.accommodations = Object.assign([], response.data.data)
          
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    clearFilter() {
      this.filter = {}
    },
    confirmSearch() {
      this.initialize()
    },

    allNameGuest(item) {
      let names = []
      item.guest.forEach(element => {
        names.push(element.name)
      })

      return names.join()
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
  },
}
</script>